import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    paddingRight: 12,
  },
  title: {
    width: '100%',
    fontWeight: 400,
    fontSize: '1rem',
    color: theme.palette.primary.main,
    marginTop: 0,
    textDecoration: 'none',
    fontFamily: FONTS.Graphik.GraphikRegular,
    textTransform: 'lowercase',
  },
  percentage: {
    fontFamily: FONTS.Graphik.GraphikLight,
    fontSize: '0.8rem',
  },
  tealColour: {
    color: '#2E7C86',
    fontWeight: 700,
  },
  lightGrey: {
    color: COLORS.lightGrey,
  },
}))
