import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from './../../constants'

export const styles = makeStyles(theme => ({
  searchForm: {
    position: 'relative',
    ['& > input']: {
      backgroundColor: theme.palette.secondary.main,
      fontFamily: FONTS.Graphik.GraphikRegular,
      color: theme.palette.common.black,
      fontSize: '0.75rem',
    },
    width: '100%',
    maxWidth: '600px',
    marginTop: 10,
  },
  searchButton: {
    position: 'absolute',
    height: '90%',
    top: '5%',
    right: 0,
    maxWidth: '25%',
    minWidth: '75px',
    background: 'white',
    border: 'none',
  },
  searchIcon: {
    maxWidth: '100%',
    width: 20,
  },

  [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
    newsletterChild: {
      width: '45%',
    },
  },

  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    newsletterChild: {
      order: 3,
      width: '40%',
    },
  },

  [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
    searchForm: {
      maxWidth: '350px',
    },
  },
}))
