import { makeStyles } from '@material-ui/core/styles'
import { FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  logo: {
    display: 'block',
    width: 32,
    height: 32,
  },
  title: {
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '0.8rem',
    marginLeft: 10,
    fontWeight: 'normal',
    padding: 0,
    margin: 0,
  },
  preText: {
    whiteSpace: 'normal',
  },
}))
