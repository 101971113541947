import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    minWidth: 120,
    maxWidth: 345,
    position: 'relative',
    borderBottom: `1px solid ${COLORS.darkGrey} !important`,
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.common.black} !important`,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectList: {
    padding: 10,
    paddingLeft: 100,
    ['& > select']: {
      padding: 0,
      paddingLeft: 10,
      paddingRight: 0,
      backgroundColor: 'unset!important',
      fontFamily: FONTS.Schnyder.SchnyderXLLight,
      fontSize: '1.875rem',
      lineHeight: 'normal',
      ['& > option']: {
        fontFamily: FONTS.Schnyder.SchnyderXLLight,
      },
    },
  },
  sortByText: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '1.875rem',
    fontWeight: 400,
    position: 'absolute',
    bottom: 7.5,
    left: 0,
    margin: 0,
  },
}))
