import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  title: {
    textTransform: 'lowercase',
    margin: '20px 0px',
  },
  suffix: {
    fontSize: '1.25rem',
    fontFamily: FONTS.Graphik.GraphikLight,
    color: COLORS.lightGrey,
  },

  brandsContainer: {
    columns: 1,
    columnFill: 'balance',
    columnGap: 20,
    minHeight: 100,
    width: '100%',
  },

  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    brandsContainer: {
      columns: 3,
    },
  },

  [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
    brandsContainer: {
      columns: 4,
    },
  },
  [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
    brandsContainer: {
      columnGap: '5%',
    },
  },
  [`@media (min-width: ${BREAKPOINTS.xxl}px)`]: {
    brandsContainer: {
      columnGap: '10%',
    },
  },
}))
