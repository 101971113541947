import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: COLORS.whiteStain,
    padding: '40px 0',
  },
  topSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 40,
    position: 'relative',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
    width: '100%',
    maxWidth: '800px',
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  chirpyestLogo: {
    height: '53px',
    margin: 'auto',
    position: 'absolute',
    display: 'block',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  black: {
    backgroundColor: COLORS.black,
  },
  rounded: {
    borderRadius: '100%',
  },
  headerBrands: {
    margin: 0,
    letterSpacing: '0.38px !important',
    marginLeft: '20px !important',
  },
  container: {
    width: '30%',
    maxWidth: 100,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100%',
  },
  imageContainer: {
    display: 'flex',
    width: '100%',
    height: 0,
    paddingBottom: '100%',
    position: 'relative',
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
  },
  description: {
    width: '100%',
    wordWrap: 'break-word',
    textAlign: 'center',
    '& p': {
      marginBottom: 0,
    },
  },

  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    chirpyestLogo: {
      height: '60px',
    },
  },

  [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
    topSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'relative',
      height: 200,
    },
    description: {
      width: '100%',
      position: 'absolute',
      bottom: -25,
      marginLeft: 120,
      textAlign: 'start',
      whiteSpace: 'pre-line',
      maxWidth: 650,
    },
    mainContainer: {
      paddingBottom: 0,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },

    bottomSection: {
      maxWidth: 'none',
      marginTop: 35,
    },
  },
  breadcrumb: {
    [`& nav.MuiBreadcrumbs-root`]: {
      marginBottom: -10,
      [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
        marginBottom: 20,
      },
    },
    [`& li.MuiBreadcrumbs-li a`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      color: COLORS.black,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      cursor: 'pointer',
      fontFamily: 'Graphik Light',
    },
    [`& li.MuiBreadcrumbs-separator`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
      marginTop: 5,
    },
    [`& li.MuiBreadcrumbs-li p`]: {
      height: 27.43,
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '31px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
    },
  },
}))
