import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    overflowX: 'scroll',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    scrollbarColor: 'transparent',
    ['&::-webkit-scrollbar']: {
      display: 'none',
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      flexWrap: 'unset',
      ['& > a']: {
        paddingRight: '0.5rem',
        paddingLeft: '0.5rem',
      },
    },
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      flexWrap: 'wrap',
    },
    justifyContent: 'space-between',
    width: '100%',
    height: 30,
    ['& > a']: {
      marginBottom: theme.spacing(1),
      fontSize: '1rem',
      fontFamily: FONTS.Graphik.GraphikRegular,
    },
  },
  rangeLink: {
    padding: '0 20px',
  },
  cashbackRoot: {
    maxWidth: 400,
  },
  disabledLink: {
    color: `${COLORS.ashGrey} !important`,
    pointerEvents: 'none',
  },
}))
