import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from '../constants'

export const styles = makeStyles(theme => ({
  controls: {
    display: 'flex',
    flexDirection: 'column',
  },
  disclosure: {
    textAlign: 'center',
    // opacity: '0.6',
    color: '#ABABAB',
    fontFamily: FONTS.Graphik.GraphikLight,
  },
  sortContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 40,
  },

  brandsListError: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingTop: 40,
  },
  scrollButton: {
    position: 'fixed',
    zIndex: 99,
    right: '1.25rem',
    bottom: '1.25rem',
    width: '4rem',
    height: '4rem',
    cursor: 'pointer',
    outline: 'none',
    textAlign: 'center',
    fontSize: 'large',
    fontWeight: 600,
    fontFamily: FONTS.Graphik.GraphikRegular,
    borderWidth: 0,
    borderRadius: '100%',
    color: COLORS.white,
    backgroundColor: COLORS.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hidden: {
    display: 'none',
  },

  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    controls: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    brandsListError: {
      paddingTop: 80,
    },
  },
  [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
    controls: {
      width: '75%',
      justifyContent: 'flex-end',
      ['& > div:first-child']: {
        marginLeft: '7%',
      },
      ['& > div:last-child']: {
        marginLeft: '5%',
      },
    },
    sortContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
  },
  [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
    controls: {
      ['& > div:last-child']: {
        marginLeft: '7%',
      },
    },
  },
}))
