import * as React from 'react'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Link from '@material-ui/core/Link'
import { ALPHABET_LIST, CASHBACK_RANGES } from '../../constants'

interface CHRBrandsFilterProps {
  data: string[]
  filterType: string
  t: TFunction
}

const CHRBrandsFilter = ({
  t,
  filterType,
  data,
  ...props
}: CHRBrandsFilterProps) => {
  const classes = styles()

  if (filterType == 1) {
    return (
      <div className={classes.root}>
        {ALPHABET_LIST.map(ele => {
          if (data.includes(ele)) {
            return (
              <Link key={ele} href={`#${ele}`}>
                {ele}
              </Link>
            )
          }
          return <Link className={classes.disabledLink}>{ele}</Link>
        })}
      </div>
    )
  } else {
    return (
      <div className={`${classes.root} ${classes.cashbackRoot}`}>
        {CASHBACK_RANGES.map((item, index) => {
          if (data.includes(`${item.min}`)) {
            return (
              <Link key={index} href={'#' + item.min}>
                {item.range}
              </Link>
            )
          }
          return (
            <Link
              rel="preconnect"
              key={index}
              href={'#' + item.min}
              className={classes.disabledLink}
            >
              {item.range}
            </Link>
          )
        })}
      </div>
    )
  }
}

export default withTranslation()(CHRBrandsFilter)
