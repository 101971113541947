import React, { useState } from 'react'
import { styles } from './styles'
import CHRInput from '../input'
import searchIcon from './../../assets/images/search.svg'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

interface CHRBrandsSearchProps {
  t: TFunction
  onTextChanged: Function
}

const CHRBrandsSearch = ({ t, onTextChanged }: CHRBrandsSearchProps) => {
  const classes = styles()
  const [searchText, setSearchText] = useState('')

  const handleChange = event => {
    setSearchText(event.target.value)
    onTextChanged(event.target.value)
  }
  const handleButtonSearch = event => {
    event.preventDefault()
  }
  return (
    <form className={classes.searchForm}>
      <CHRInput
        type="text"
        value={searchText}
        placeholder={t('shared.searchBrandsAndRetailers')}
        onChange={e => {
          handleChange(e)
        }}
      />
      <button className={classes.searchButton} onClick={handleButtonSearch}>
        <img
          src={searchIcon}
          alt={t('imageAlts.search')}
          className={classes.searchIcon}
        />
      </button>
    </form>
  )
}

export default withTranslation()(CHRBrandsSearch)
