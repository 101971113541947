import React, { useState, useEffect } from 'react'

interface DelayedProps {
  waitBeforeShow: number
  children: React.ReactNode
}

const Delayed = ({ waitBeforeShow, children }: DelayedProps) => {
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    const timeoutId = setTimeout(() => setHidden(false), waitBeforeShow)
    return () => clearTimeout(timeoutId)
  }, [])

  return !hidden && children
}

export default Delayed
