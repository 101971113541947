import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  textContainer: {
    width: 199,
    fontSize: 12,
    fontFamily: FONTS.Graphik.GraphikLight,
    fontWeight: 300,
  },
  imageContainer: {
    width: 30,
    height: 31,
    marginRight: 15,
  },
  browserExtensionPopUpPosition: {
    position: 'absolute',
    top: 0,
    right: 155,
  },
  addExtensionPopUpPosition: {
    position: 'absolute',
    top: 0,
    right: 475,
  },
  pinExtensionPopUpPosition: {
    position: 'absolute',
    top: 0,
    right: 155,
  },
  cashbackPopUpPosition: {
    position: 'absolute',
    top: 720,
    right: '50%',
  },
  popper: {
    zIndex: 1,
    pointerEvents: 'visible',
  },
  extension: {
    width: 27,
    height: 27,
    verticalAlign: 'middle',
  },
  icon: {
    width: 35,
    height: 35,
    verticalAlign: 'middle',
  },
  popOutText: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: 40,
    marginBottom: 5,
    lineHeight: '45px',
  },
  link: {
    color: COLORS.darkGrey2,
    fontFamily: FONTS.Graphik.GraphikLight,
  },
  addExtensionText: {
    color: COLORS.darkGrey2,
    fontFamily: FONTS.Graphik.GraphikLight,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  closeButton: {
    position: 'absolute',
    top: '15%',
    right: '3%',
    width: 8,
    height: 8,
    cursor: 'pointer',
    backgroundColor: theme.palette.common.black,
    border: 'none',
    outline: 'none',
  },
  pinExtensionDialog: {
    width: 413,
    height: 270,
    margin: '0 50px 50px 65px',
  },
  welcomePopUpTitle: {
    marginTop: 10,
  },
  welcomeDialog: {
    width: 480,
    height: 450,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 40px 0px',
  },
  paper: {
    width: 613,
    height: 590,
  },
  popout: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      display: 'none',
    },
  },
  articleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  articleContentSecondParagraph: {
    flexDirection: 'row-reverse',
  },
  welcomePopUpIcon: {
    minWidth: 60,
    maxWidth: 60,
    width: 60,
    height: 60,
    minHeight: 60,
    maxHeight: 60,
  },
  welcomPopUpParagraph: {
    width: 370,
    lineHeight: 1.5,
    textAlign: 'justify',
    fontSize: 16,
    fontFamily: 'Graphik Light',
    fontWeight: 400,
  },
  activateCashbackBtn: {
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    marginRight: 15,
    '& > button': {
      pointerEvents: 'none',
    },
  },
  articleContentLinks: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 40,
    '& > a:first-child': {
      marginRight: 40,
    },
  },
  smallButton: {
    height: 32,
  },
  smallText: {
    fontSize: 6.5,
  },
}))
