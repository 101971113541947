import React, { useState } from 'react'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { FormControl } from '@material-ui/core'
import Select from 'react-select'
import { useAppState } from '../../appState'

interface BrandsSortProps {
  count: number
  selectedFilter: number
  onFilterChange: () => void
  t: TFunction
}

const CHRBrandsSort = ({
  t,
  count,
  onFilterChange,
  selectedFilter,
  ...props
}: BrandsSortProps) => {
  const [appState] = useAppState()
  const classes = styles()
  const [selectedOption, setSelectedOption] = useState(
    appState.settings.brandsView || {
      value: 1,
      label: t('shared.alphabetical'),
    }
  )

  const options = [
    { value: 1, label: t('shared.alphabetical') },
    { value: 2, label: t('shared.lowestToHighest') },
    { value: 3, label: t('shared.highestToLowest') },
  ]

  const handleChange = event => {
    setSelectedOption(event)
    const filter = event.value
    onFilterChange(filter)
  }

  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      width: '210px',
      padding: 0,
      marginLeft: 10,
      cursor: 'pointer',
      border: 'none !important',
      boxShadow: 'none',
      '&:hover': {
        border: 'none !important',
      },
    }),

    option: styles => {
      return {
        ...styles,
        fontFamily: 'Schnyder XL Light',
        fontSize: 30,
        padding: '0 0 0 10px',
      }
    },

    valueContainer: styles => ({
      height: 50,
    }),

    singleValue: styles => ({
      ...styles,
      top: 'unset',
      bottom: -25,
      fontFamily: 'Schnyder XL Light',
      fontSize: '1.875rem',
      fontWeight: 400,
    }),

    placeholder: styles => ({
      display: 'none',
    }),

    input: styles => ({
      color: 'transparent',
    }),

    menu: styles => ({
      ...styles,
      width: 250,
    }),
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <h4 className={classes.sortByText}>{t('shared.sortBy')}</h4>
        <Select
          defaultValue={selectedOption}
          value={selectedOption}
          className={classes.selectList}
          onChange={handleChange}
          options={options}
          styles={colourStyles}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
        />
      </FormControl>
    </div>
  )
}

export default withTranslation()(CHRBrandsSort)
