import * as React from 'react'
import { styles } from './styles'
import ChirpyestLogo from '../../assets/images/chirpyest-white.svg'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import CHRBrandsSearch from '../searchForm'
import CHRBrandsFilter from '../brandsFilter'
import classNames from 'classnames'
import ContentContainer from './../contentContainer'
import Typography from '@material-ui/core/Typography'
import Links from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { navigate } from 'gatsby'
import { ROUTES } from '../../constants'
interface BrandsHeaderProps {
  alphabetList: string[]
  selectedFilter: Number
  onSearchTextChanged: Function
  t: TFunction
}

const CHRBrandsHeader = ({
  t,
  alphabetList,
  selectedFilter,
  onSearchTextChanged,
  ...props
}: BrandsHeaderProps) => {
  const classes = styles()
  const container = classNames({
    [classes.black]: true,
    [classes.large]: true,
    [classes.rounded]: true,
    [classes.imageContainer]: true,
  })

  return (
    <div className={classes.root}>
      <ContentContainer>
        <div className={classes.breadcrumb}>
          <Breadcrumbs aria-label="breadcrumb">
            <Links
              color="inherit"
              rel="preconnect"
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => {
                event.preventDefault()
                navigate(ROUTES.home)
              }}
            >
              home
            </Links>
            <Typography color="textPrimary">brands</Typography>
          </Breadcrumbs>
        </div>
      </ContentContainer>
      <ContentContainer>
        <div className={classes.topSection}>
          <div className={classes.mainContainer}>
            <div className={classes.container}>
              <div className={classes.imageContainer}>
                <img
                  src={ChirpyestLogo}
                  className={classes.chirpyestLogo}
                  alt={t('shared.chirpyest')}
                />
              </div>
            </div>
            <Typography variant="h1" className={classes.headerBrands}>
              {t('shared.brandsAndRetailers')}
            </Typography>
          </div>
          <div className={classes.description}>
            <Typography variant="subtitle1" component="p">
              {t('brands.description')}
            </Typography>
            <Typography variant="subtitle1" component="p">
              {`💃🏿: special cash back - limited time only`}
            </Typography>
          </div>
          <CHRBrandsSearch onTextChanged={onSearchTextChanged} />
        </div>
        <div className={classes.bottomSection}>
          <CHRBrandsFilter data={alphabetList} filterType={selectedFilter} />
        </div>
      </ContentContainer>
    </div>
  )
}

export default withTranslation()(CHRBrandsHeader)
